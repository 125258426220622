<template>
  <div class="suppliers-table" :dir="$direction">
    <TableLoadingSkeleton v-if="suppliersPurchaseManagementLoading" />
    <Table
      v-else
      ref="tableRef"
      :use-default-wrapper="false"
      class="top-rounded"
      :sub-header="amountOfComparisons > 1"
      :active-sort="activeSort"
      :data="mutatedSuppliersItems"
      :columns="columnState"
      sticky-columns="1"
      show-index
      @row-click="onRowClick"
    >
      <template
        #sub-header="{
          showIndex,
          hasWidth,
          stickyColumns,
          additionalColumns,
          columns: subHeaderColumns,
          getAdditionalColumnStickyBounds,
          isSticky,
          getHeaderStyle,
        }"
      >
        <SubHeader
          custom-index-class-name="sub-header-bg border-bottom border-right"
          :show-index="showIndex"
          :has-width="hasWidth"
          :sticky-columns="stickyColumns"
          :additional-columns="additionalColumns"
          :columns="subHeaderColumns"
          :get-additional-column-styles="getAdditionalColumnStickyBounds"
          :is-column-index-sticky="isSticky"
          :get-header-style="getHeaderStyle"
        >
          <template #sub-header-productCount="{ column: { width } }">
            <div class="sub-header-comparison">
              <div
                v-for="index in amountOfComparisons"
                :key="`sub-avg-label-${index}`"
                :style="{ width: `${Number(width.split('px')[0]) / amountOfComparisons}px` }"
              >
                <el-tooltip placement="top" effect="dark" :content="dateRangesLabel[index - 1]">
                  <component :is="getNumberIconComponentName(index)" v-bind="{ size: 16, fill: '#9295A5' }" />
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #sub-header-orderCount="{ column: { width } }">
            <div class="sub-header-comparison">
              <div
                v-for="index in amountOfComparisons"
                :key="`sub-avg-label-${index}`"
                :style="{ width: `${Number(width.split('px')[0]) / amountOfComparisons}px` }"
              >
                <el-tooltip placement="top" effect="dark" :content="dateRangesLabel[index - 1]">
                  <component :is="getNumberIconComponentName(index)" v-bind="{ size: 16, fill: '#9295A5' }" />
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #sub-header-totalPurchases="{ column: { width } }">
            <div class="sub-header-comparison">
              <div
                v-for="index in amountOfComparisons"
                :key="`sub-total-label-${index}`"
                :style="{ width: `${Number(width.split('px')[0]) / amountOfComparisons}px` }"
              >
                <el-tooltip placement="top" effect="dark" :content="dateRangesLabel[index - 1]">
                  <component :is="getNumberIconComponentName(index)" v-bind="{ size: 16, fill: '#9295A5' }" />
                </el-tooltip>
              </div>
            </div>
          </template>
        </SubHeader>
      </template>
      <template #header-index>
        <div class="table-header-tab">
          <p class="fw-bold d-inline">#</p>
          <div class="table-header-tab-current-period">
            <TableTab>
              <p>{{ $t('terms.supplierTerms.suppliersTable.summary.rightHeader.title') }}</p>
              <el-tooltip placement="top" effect="dark">
                <div slot="content" class="table-header-tooltip">
                  <span class="bold">{{ $t('terms.supplierTerms.suppliersTable.summary.rightHeader.title') }} - </span>
                  <span>{{ $t('terms.supplierTerms.suppliersTable.summary.rightHeader.desc') }}</span>
                </div>
                <SimulationQuestionMarkIcon class="tooltip-icon" />
              </el-tooltip>
            </TableTab>
          </div>
        </div>
      </template>

      <template #[firstVisibleColumnInPreviousPeriodCategory]="{ column: { header } }">
        <div class="table-header-tab">
          <p class="fw-bold d-inline">{{ header }}</p>
          <div class="table-header-tab-previous-period">
            <TableTab>
              <TruncatedText :style="{ width: previousPeriodTabWidth }">{{
                $t('terms.supplierTerms.suppliersTable.summary.leftHeader.title')
              }}</TruncatedText>
              <el-tooltip placement="top" effect="dark">
                <div slot="content" class="table-header-tooltip">
                  <span class="bold">{{ $t('terms.supplierTerms.suppliersTable.summary.leftHeader.title') }} - </span>
                  <span>{{ $t('terms.supplierTerms.suppliersTable.summary.leftHeader.desc') }}</span>
                </div>
                <SimulationQuestionMarkIcon class="tooltip-icon" />
              </el-tooltip>
            </TableTab>
          </div>
        </div>
      </template>

      <template #cell-supplier="{ rowData: comparisonItem }">
        <span :set="(item = getComparisonItem(comparisonItem, 1, true))">
          <TruncatedText>
            {{ item.supplier.name }}
          </TruncatedText>
        </span>
      </template>

      <template #cell-productCount="{ rowData: comparisonItem }">
        <div class="comparison-container">
          <div
            v-for="index in amountOfComparisons"
            :key="`product-count-comparison-${index - 1}`"
            :set="(item = getComparisonItem(comparisonItem, index))"
            :style="{ width: getWidth(SUPPLIERS_TABLE_HEADERS.PRODUCT_COUNT) }"
          >
            <span :class="$t('direction') === 'rtl' ? 'ms-2' : 'me-2'">{{
              formatNumberOrDefaultZero(item?.productCount)
            }}</span>
          </div>
        </div>
      </template>

      <template #cell-orderCount="{ rowData: comparisonItem }">
        <div class="comparison-container">
          <div
            v-for="index in amountOfComparisons"
            :key="`order-count-comparison-${index - 1}`"
            :set="(item = getComparisonItem(comparisonItem, index))"
            :style="{ width: getWidth(SUPPLIERS_TABLE_HEADERS.ORDER_COUNT) }"
          >
            <span :class="$t('direction') === 'rtl' ? 'ms-2' : 'me-2'">{{ formatNumber(item?.orderCount) }}</span>
          </div>
        </div>
      </template>

      <template #cell-totalPurchases="{ rowData: comparisonItem }">
        <div class="comparison-container">
          <div
            v-for="index in amountOfComparisons"
            :key="`total-purchases-comparison-${index - 1}`"
            :set="(item = getComparisonItem(comparisonItem, index))"
            :style="{ width: getWidth(SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES) }"
          >
            <PriceWithPercentage
              v-if="item && item.productCount > 0"
              :price="item.price ? item.price : 0"
              :percentage="calculatePercentage(item && item.price ? item.price : 0, sumPurchases[index - 1])"
              :percentage-tool-tip-text="$t('terms.supplierTerms.supplierTermTable.percentageFromBasket')"
            />
            <p v-else class="missing-data">{{ MISSING_DATA_TEXT }}</p>
          </div>
        </div>
      </template>

      <template #cell-productCountWithDifferentPrices="{ rowData: comparisonItem }">
        <div ref="width" :set="(item = getComparisonItem(comparisonItem, 1, true))">
          <p v-if="item && (item.productCountWithDifferentPrices || item.productCountWithDifferentPrices === 0)">
            {{ formatNumberOrDefaultZero(item?.productCountWithDifferentPrices) }}
          </p>
          <p v-else class="missing-data">{{ MISSING_DATA_TEXT }}</p>
        </div>
      </template>

      <template #cell-difference="{ rowData: comparisonItem }">
        <div class="difference-percentage" :set="(item = getComparisonItem(comparisonItem, 1, true))">
          <p v-if="item.percentageChange && item.percentageChange !== 0">{{ formatMoney(item.difference) }}</p>
          <p v-else class="missing-data">{{ MISSING_DATA_TEXT }}</p>
          <el-tooltip v-if="item.percentageChange && item.percentageChange !== 0" placement="top" effect="dark">
            <div slot="content">
              <span>{{ $t('terms.supplierTerms.supplierTermTable.changesSinceLastPeriod') }}</span>
            </div>
            <PercentageChange :percentage-change="item.percentageChange" />
          </el-tooltip>
        </div>
      </template>

      <template #cell-annualImpact="{ rowData: comparisonItem }">
        <div class="annual-impact" :set="(item = getComparisonItem(comparisonItem, 1, true))">
          <p v-if="!item.annualImpact" class="missing-data">{{ MISSING_DATA_TEXT }}</p>
          <p v-else>{{ formatMoney(item.annualImpact) }}</p>
          <p v-if="!item.annualImpact"></p>
          <SimulationTableArrowUpIcon v-else-if="item.annualImpact > 0" :size="20" fill="#E52044" />
          <SimulationTableArrowDownIcon v-else :size="20" fill="#00B37A" />
        </div>
      </template>
    </Table>
  </div>
</template>
<script>
import { computed, ref, onMounted, toRefs, watch } from 'vue';
import { clone } from 'ramda';
import { useCurrency } from '@/locale/useCurrency';
import { Table, TruncatedText, TableLoadingSkeleton } from '@/modules/core/components';
import { getSuppliersTableColumns } from './suppliersTableColumns';
import {
  SimulationQuestionMarkIcon,
  SimulationTableArrowUpIcon,
  SimulationTableArrowDownIcon,
  OneIcon,
  TwoIcon,
  ThreeIcon,
} from '@/assets/icons';
import { MISSING_DATA_TEXT, LESS_THAN_LTR } from '@/modules/purchase-management/tools/constants';

import { SUPPLIERS_TABLE_HEADERS } from './suppliersTableConstants';
import { COLUMN_CATEGORIES } from '../supplierProductsPurchaseManagement/supplierProductsTableConstants';
import {
  formatCurrencyOrDash,
  formatPercentOrDash,
  formatDateOrDash,
  formatNumberOrDefaultZero,
  formatNumberOrDash,
} from '../../purchaseManagementFormatters';
import { calculateProductsSum, getOnFilterHandler, getNumberIconComponentName } from '../../purchaseManagement';
import PercentageChange from '../PercentageChange.vue';
import { mutateSuppliers } from './suppliersTable';
import PriceWithPercentage from '../PriceWithPercentage.vue';
import TableTab from '../TableTab.vue';
import SubHeader from '@/modules/core/components/SubHeader.vue';

export default {
  components: {
    Table,
    TableTab,
    OneIcon,
    TwoIcon,
    ThreeIcon,
    SubHeader,
    TruncatedText,
    TableLoadingSkeleton,
    SimulationQuestionMarkIcon,
    SimulationTableArrowUpIcon,
    SimulationTableArrowDownIcon,
    PriceWithPercentage,
    PercentageChange,
  },
  props: {
    suppliersPurchaseManagementData: {
      type: Object,
      default: () => ({ supplierPurchaseSummary: [], productPurchaseSummary: [] }),
    },
    suppliersPurchaseManagementLoading: { type: Boolean, default: false },
    columns: { type: Array, required: true },
    dateRanges: { type: Array, required: true },
    dateRangesLabel: { type: Array, required: true },
    searchSupplierText: { type: String, required: true },
  },
  emits: ['on-update-mutated-data'],
  setup(props, { emit }) {
    const { currencyFormat } = useCurrency();

    const activeSort = ref({ direction: -1, columnKey: SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES });
    const columnState = toRefs(props).columns;
    const defaultColumns = ref({});
    const tableRef = ref(null);
    const fullTableWidth = ref(null);
    const widthForCurrentPeriodColumns = ref(null);
    const isComparison = computed(() => props.dateRanges.length > 1);

    const mutatedSuppliersItems = computed(() =>
      mutateSuppliers({
        objectifiedData: props.suppliersPurchaseManagementData,
        activeSort: activeSort.value,
        supplierSearchFilter: props.searchSupplierText,
        isComparison: isComparison.value,
      })
    );

    const amountOfComparisons = computed(() => props.dateRanges.length);

    const sumPurchasesComputed = computed(() => {
      const sumPurchasesForComparison = [];
      const supplierOverviewKeys = Object.keys(props.suppliersPurchaseManagementData).filter(
        (key) => key != 'productPurchaseSummary'
      );
      for (let i = 0; i < amountOfComparisons.value; i++) {
        sumPurchasesForComparison.push(
          calculateProductsSum(props.suppliersPurchaseManagementData[supplierOverviewKeys[i]], 'amount')
        );
      }

      return sumPurchasesForComparison;
    });

    const calculatePercentage = (sumPrice, total) => (sumPrice / total) * 100;

    const previousPeriodTabWidth = computed(() => {
      const previousPeriod = columnState.value.filter(
        (column) => !column.hidden && column.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED
      );

      if (previousPeriod.length > 1) {
        return '260px';
      } else {
        const width = Number(previousPeriod[0].width.split('px')[0]);
        return `${width}px`;
      }
    });

    watch(
      mutatedSuppliersItems,
      () => {
        emit('on-update-mutated-data', { data: mutatedSuppliersItems.value });
      },
      { immediate: true }
    );

    const firstVisibleColumnInPreviousPeriodCategory = computed(() => {
      const onlyShownColumns = columnState.value.filter((value) => !value.hidden);
      const productsPurchasedColumns = onlyShownColumns.filter(
        (columns) => columns.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED
      );
      if (productsPurchasedColumns.length === 0) return '';

      return `header-${productsPurchasedColumns[0].key}`;
    });

    const customClassesState = computed(() => {
      const onlyShownColumns = columnState.value.filter((value) => !value.hidden);
      const productsPurchased = onlyShownColumns.filter(
        (columns) => columns.category === COLUMN_CATEGORIES.PRODUCTS_PURCHASED
      );
      const previousPeriod = onlyShownColumns.filter(
        (columns) => columns.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED
      );
      const productsPurchasedColumns = productsPurchased.map((categoryColumn, index) => {
        const comparisonBorder = isComparison.value && categoryColumn.isComparisonColumn ? 'border-right' : '';
        if (index === productsPurchased.length - 1 && previousPeriod.length === 0) {
          const comparisonLastBorder =
            isComparison.value && categoryColumn.isComparisonColumn ? 'border-left-right' : 'border-left';

          return {
            ...categoryColumn,
            customClass: comparisonLastBorder,
            subHeaderCustomClass: `sub-header-bg ${comparisonLastBorder}`,
          };
        }

        return {
          ...categoryColumn,
          customClass: comparisonBorder,
          subHeaderCustomClass: `sub-header-bg ${comparisonBorder}`,
        };
      });
      const previousPeriodColumns = previousPeriod.map((categoryColumn, index) => {
        if (index === 0 && index === previousPeriod.length - 1) {
          return {
            ...categoryColumn,
            customClass: 'border-left-right',
          };
        }
        if (index === 0) {
          return {
            ...categoryColumn,
            customClass: 'border-right',
            subHeaderCustomClass: 'sub-header-bg border-right',
          };
        }

        if (index === previousPeriod.length - 1) {
          return {
            ...categoryColumn,
            customClass: 'border-left',
            subHeaderCustomClass: 'sub-header-bg border-left',
          };
        }

        return {
          ...categoryColumn,
          customClass: '',
          subHeaderCustomClass: 'sub-header-bg',
        };
      });
      return [...productsPurchasedColumns, ...previousPeriodColumns];
    });

    const getComparisonItem = (comparisonItem, index, forceValue = false) => {
      let returnItem = comparisonItem[`comparison-${index - 1}`];
      if (forceValue) {
        for (let i = 0; i < amountOfComparisons.value; i++) {
          if (returnItem.supplier.id) break;

          returnItem = comparisonItem[`comparison-${i}`];
        }
      }
      return returnItem;
    };

    const getWidth = (key) => {
      const columns = getSuppliersTableColumns({
        activeSort: { value: null },
        supplierSearchFilter: null,
      });

      return columns.find((column) => column.key === key).width;
    };

    const onRowClick = (args) => {
      emit('row-click', mutatedSuppliersItems.value[args]['comparison-0']);
    };

    onMounted(() => {
      defaultColumns.value = clone(columnState.value);
    });

    return {
      LESS_THAN_LTR,
      MISSING_DATA_TEXT,
      SUPPLIERS_TABLE_HEADERS,
      activeSort,
      tableRef,
      sumPurchases: sumPurchasesComputed,
      amountOfComparisons,
      mutatedSuppliersItems,
      widthForCurrentPeriodColumns,
      fullTableWidth,
      previousPeriodTabWidth,
      columnState: customClassesState,
      firstVisibleColumnInPreviousPeriodCategory,
      getWidth,
      onRowClick,
      getComparisonItem,
      getNumberIconComponentName,
      formatMoney: (number) => formatCurrencyOrDash(number, currencyFormat.value),
      formatPercent: formatPercentOrDash,
      formatNumberOrDefaultZero,
      formatNumber: formatNumberOrDash,
      formatDate: formatDateOrDash,
      calculatePercentage,
      onSupplierNameFilter: getOnFilterHandler({
        currentColumns: columnState.value,
        emit: emit,
        filteredColumnKey: SUPPLIERS_TABLE_HEADERS.SUPPLIER,
      }),
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .table-hover > tbody > tr:hover > * {
    background-color: #f8fafb;
  }
  .sub-header-bg {
    height: 24px;
    background-color: $gray;
  }
  th {
    box-shadow: inset 0 1px 0 0 $outline;
  }
  th.header-index {
    [dir='ltr'] & {
      box-shadow: inset 1px 1px 0 0 $outline;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 1px 0 0 $outline;
    }
  }
  td.cell-index {
    [dir='ltr'] & {
      box-shadow: inset 1px 0 0 0 $outline;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 0 0 0 $outline;
    }
  }
  th.border-right {
    [dir='ltr'] & {
      box-shadow: inset 1px 1px 0 0 $outline;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 1px 0 0 $outline;
    }
  }
  td.border-right {
    [dir='ltr'] & {
      box-shadow: inset 1px 0 0 0 $outline;
    }
    [dir='rtl'] & {
      box-shadow: inset -1px 0 0 0 $outline;
    }
  }
  th.border-left {
    [dir='ltr'] & {
      box-shadow: inset -1px 1px 0 0 $outline;
    }
    [dir='rtl'] & {
      box-shadow: inset 1px 1px 0 0 $outline;
    }
  }
  td.border-left {
    [dir='ltr'] & {
      box-shadow: inset -1px 0 0 0 $outline;
    }
    [dir='rtl'] & {
      box-shadow: inset 1px 0 0 0 $outline;
    }
  }
  th.border-left-right {
    [dir='ltr'] & {
      border-right: 1px solid $outline;
      box-shadow: inset 1px 1px 0 0 $outline;
    }
    [dir='rtl'] & {
      border-left: 1px solid $outline;
      box-shadow: inset -1px 1px 0 0 $outline;
    }
  }
  td.border-left-right {
    [dir='ltr'] & {
      border-right: 1px solid $outline;
      box-shadow: inset 1px 0 0 0 $outline;
    }
    [dir='rtl'] & {
      border-left: 1px solid $outline;
      box-shadow: inset -1px 0 0 0 $outline;
    }
  }
  .bg-neutral {
    background-color: #fbfbfb;
  }
  .table-responsive {
    width: fit-content;
  }
  .sub-header-bg {
    height: 24px;
    padding: 0.2rem 1rem;
    background-color: #fbfbfb;
  }
}

.border-inline-start {
  border-inline-start: 1px solid $outline;
}

.parent-header {
  background-color: $gray;
  border-bottom: 1px solid $outline;
}
.table-header-tooltip {
  width: 244px;
}
.tooltip-icon {
  cursor: pointer;
}
.top-rounded {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.difference-percentage {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.bold {
  font-weight: 500;
}

.table-header-tab {
  position: relative;
  .table-header-tab-current-period {
    height: 39px;
    position: absolute;
    top: -47px;
    width: 330px;

    [dir='rtl'] & {
      right: -16px;
    }
    [dir='ltr'] & {
      left: -16px;
    }
  }
  .table-header-tab-previous-period {
    height: 39px;
    position: absolute;
    top: -47px;
    width: 330px;

    [dir='rtl'] & {
      right: -16px;
    }
    [dir='ltr'] & {
      left: -16px;
    }
  }
}

.suppliers-table {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 6px;
  border-bottom: 1px solid #d9dcde;
  padding-top: 38px;
  .table-header {
    display: flex;
    background-color: $gray;
    border-bottom: 1px solid $outline;
    justify-content: flex-start;
    .table-header-text {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5625rem 1rem;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
.sub-header-comparison {
  display: flex;
}
.comparison-container {
  display: flex;
}
.missing-data {
  color: $typography-secondary;
}
.annual-impact {
  display: flex;
}
</style>
