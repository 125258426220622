<template>
  <Carousel class="dashboard">
    <Slide class="h-100 carusel-slide">
      <PriceChangeImplicationYearlySummary
        :loading="priceChangeImplicationsLoading"
        :summary-data="summaryData"
        :is-compact-view="true"
        @moreDetailsClick="navigateToPriceChangeImplications"
      />
    </Slide>
    <Slide class="h-100 carusel-slide">
      <SupplierPurchasesPie />
    </Slide>
    <Slide class="h-100 carusel-slide">
      <PurchaseDataCard />
    </Slide>
    <Slide class="w-25 carusel-slide">
      <SupplierPurchasesChart />
    </Slide>
  </Carousel>
</template>
<script>
import { computed, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';

import { useTenancy } from '@/modules/auth';
import { usePriceChangeImplications } from '@/modules/purchase-management/compositions/priceChangeImplications/priceChangeImplications';
import PriceChangeImplicationYearlySummary from '@/modules/purchase-management/components/priceChangeImplications/PriceChangeImplicationYearlySummary';
import { PurchaseDataCard } from '@/modules/order';

import SupplierPurchasesChart from '../supplierPurchases/SupplierPurchasesChart';
import SupplierPurchasesPie from '../supplierPurchases/SupplierPurchasesPie';
import Carousel, { Slide } from '../Carousel';

export default {
  components: {
    SupplierPurchasesChart,
    SupplierPurchasesPie,
    Carousel,
    Slide,
    PurchaseDataCard,
    PriceChangeImplicationYearlySummary,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const customerId = computed(() => currentTenant.value.id);
    const toDate = DateTime.now();
    const fromDate = toDate.minus({ days: 90 });
    const { loading: priceChangeImplicationsLoading, summaryData } = usePriceChangeImplications({
      customerId,
      fromDate: fromDate.toISODate(),
      toDate: toDate.toISODate(),
    });
    const navigateToPriceChangeImplications = () => {
      root.$router.push({ name: 'priceChangeImplications' });
    };
    return {
      summaryData,
      priceChangeImplicationsLoading,
      navigateToPriceChangeImplications,
    };
  },

  methods: {
    routeToSupplier({ supplier }) {
      this.$router.push({
        name: 'supplierProductsPurchaseManagement',
        params: { supplierId: supplier.id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.dashboard ::v-deep {
  height: $page-layout-dashboard-hight + 3rem;

  .VueCarousel-wrapper {
    flex: 1;
  }

  .card {
    height: $page-layout-dashboard-hight;
  }
}

.carusel-slide {
  padding: 0 0.5rem;
}
</style>
