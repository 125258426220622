<template>
  <div>
    <div class="table-actions">
      <div>
        <h3>{{ $t('terms.tableTitle') }}</h3>
      </div>
      <div class="table-buttons">
        <ExcelExport :export-options="exportExcelOptions" @on-export-option-choice="onExportOptionChoice" />
      </div>
    </div>
    <div class="table-description">
      <SearchBar
        :search-text-ref="supplierSearchText"
        :input-placeholder="$t('terms.supplierTerms.supplierTermTable.settings.searchSupplier')"
        @on-supplier-text-change="onSupplierTextChange"
      />
      <DisplayModeDateViews
        :preset-type="PRESET_TYPES.SUPPLIER"
        :columns="columns"
        :date-ranges-state="dateRangesState"
        :presets-to-ignore="[PRESETS.simulation]"
        :date-ranges-label="dateRangesLabel"
        @on-columns-update="onColumnsUpdate"
        @on-date-range-update="(updatedDateRange) => $emit('on-date-range-update', updatedDateRange)"
      />
    </div>
    <div>
      <SuppliersPurchaseMainTable
        :suppliers-purchase-management-data="
          suppliersPurchaseManagementData?.productPurchaseSummary ? suppliersPurchaseManagementData : {}
        "
        :suppliers-purchase-management-loading="suppliersPurchaseManagementLoading"
        :date-ranges="dateRangesState"
        :date-ranges-label="dateRangesLabel"
        :columns="columns"
        :search-supplier-text="supplierSearchText"
        @row-click="onRowClick"
        @on-update-mutated-data="onUpdateMutatedData"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { ExcelExport } from '@/modules/core';
import {
  PRESETS,
  PRESET_TYPES,
  EXPORT_ACTIONS,
  exportExcelOptions,
} from '@/modules/purchase-management/tools/constants';

import { SUPPLIERS_TABLE_HEADERS } from './suppliersTableConstants';
import { exportSuppliers } from './suppliersTableExcel';
import { getSuppliersTableColumns } from './suppliersTableColumns';
import SuppliersPurchaseMainTable from './SuppliersPurchaseMainTable';
import SearchBar from '../SearchBar';
import DisplayModeDateViews from '../DisplayModeDateViews';
import { getDateRangeStringFromDates } from '../../purchaseManagementFormatters';

export default {
  components: {
    SuppliersPurchaseMainTable,
    SearchBar,
    ExcelExport,
    DisplayModeDateViews,
  },
  props: {
    suppliersPurchaseManagementData: { type: Object, required: true },
    suppliersPurchaseManagementLoading: { type: Boolean, required: true },
    dateRangesState: { type: Array, required: true, default: () => [] },
  },
  emits: ['on-row-click', 'on-mode-change', 'on-date-range-update'],
  setup(props, { emit }) {
    const tableDataReadyForExcel = ref(null);
    const supplierSearchText = ref('');

    const activeSort = ref({
      direction: -1,
      columnKey: SUPPLIERS_TABLE_HEADERS.TOTAL_PURCHASES,
    });

    const { currencyFormat } = useCurrency();

    const dateRangesLabel = computed(() =>
      props.dateRangesState.map((dateRange) =>
        getDateRangeStringFromDates(dateRange.startDate, dateRange.endDate, false)
      )
    );
    const columns = ref(getSuppliersTableColumns(activeSort, supplierSearchText.value));

    const amountOfComparisons = computed(() => props.dateRangesState.length);

    const mutatedColumns = computed(() => {
      const originalColumns = getSuppliersTableColumns(activeSort, supplierSearchText.value);
      const comparisonOriginalColumns = originalColumns.filter((column) => column.isComparisonColumn);

      return columns.value.map((column) => {
        if (!column.isComparisonColumn) return column;

        const originalColumn = comparisonOriginalColumns.find(({ key }) => column.key === key);
        const originalWidth = originalColumn ? originalColumn.width.split('px')[0] : 0;

        return {
          ...column,
          width: `${originalWidth * amountOfComparisons.value}px`,
        };
      });
    });

    const onExportOptionChoice = ({ action }) => {
      switch (action) {
        case EXPORT_ACTIONS.EXPORT_TABLE:
          exportExcel();
          break;
        case EXPORT_ACTIONS.EXPORT_ALL_PRODUCTS:
          emit('on-export-products');
          break;
        default:
          break;
      }
    };

    const exportExcel = () => {
      if (tableDataReadyForExcel.value) {
        const columnsForExcel = columns.value.filter((column) => !(column.hidden === true));
        const argsForExport = {
          suppliersItems: tableDataReadyForExcel.value,
          tableColumns: columnsForExcel,
          dateRanges: dateRangesLabel.value,
          currencyFormat: currencyFormat.value,
        };
        exportSuppliers(argsForExport);
      }
    };

    const onColumnsUpdate = (columnsToUpdate) => (columns.value = columnsToUpdate);

    const onRowClick = (args) => emit('on-row-click', args);

    const onUpdateMutatedData = ({ data }) => (tableDataReadyForExcel.value = data);

    const onSupplierTextChange = (supplierText) => (supplierSearchText.value = supplierText);

    return {
      PRESETS,
      PRESET_TYPES,
      exportExcelOptions: exportExcelOptions(),
      columns: mutatedColumns,
      onColumnsUpdate,
      supplierSearchText,
      dateRangesLabel,
      onRowClick,
      onUpdateMutatedData,
      onExportOptionChoice,
      onSupplierTextChange,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-picker-panel__shortcut {
    word-break: break-word;
  }
  .el-picker-panel__body {
    margin-inline-start: 190px !important;
  }
  .el-picker-panel__sidebar {
    width: 200px;
    padding: 16px;
  }
  .el-picker-panel__sidebar > .el-picker-panel__shortcut:first-child {
    color: #606266;
    font-weight: bold;
    margin-bottom: 4px;
    cursor: default;
  }
}
.table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.25rem;

  .table-buttons {
    display: flex;
    gap: 0.5rem;

    .table-button {
      padding: 0.25rem;
      margin: 0;
    }
  }
}

.table-description {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}
</style>
